body {
    background-color: #f2f2f2;
    font-family: Arial, sans-serif;
  }

  .main_page_fail{
    margin: 0 auto;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container_fail {
    max-width: 400px;
    // margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .fail_hi {
    text-align: center;
    color: #ff0000;
  }

  .fail_p {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
  }

  .button_fail {
    display: block;
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: #ff0000;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
  }

  .button:hover {
    background-color: #cc0000;
  }

  .cross {
    font-size: 50px;
    text-align: center;
    color: #ff0000;
  }