

  .archive-button {
    /* Your button styles */
    /* background-color: ; */
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .archive-button:hover {
    /* Styles to apply when hovering */
    /* background-color: white; */
  }

.table-container {
  overflow-x: auto;
}

/* Style the pagination container */
.react-bootstrap-table-pagination {
  margin-top: 15px;
}

.react-bootstrap-table-pagination-list{
  position: relative;
}

.react-bootstrap-table-page-btns-ul{
  position: absolute;
  right: 0;
}

/* Style the pagination buttons */
 .page-item {
  margin: 0 2px;
}

/* .react-bootstrap-table-pagination .page-link {
  
  height: 1rem;
  width: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* Style the active page button */
 .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  height: 2rem;
  width: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the pagination buttons on hover */
 .page-item:not(.disabled):hover .page-link {
  background-color: #e9ecef;
}

/* Style the previous and next buttons */
 .page-item:first-child .page-link,
 .page-item:last-child .page-link {
  border-radius: 0;
}

/* Style the pagination buttons text color */
 .page-link {
  color: #333;
  height: 2rem;
  width: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

