.header {
	text-align: center;
}

.toast {
	margin: 0 auto;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* for wizard */
.moving-tab {
	display: none;
}

.wizard-next-button {
	color: #fff !important;
	background-color: #17a2b8 !important;
	border-color: #17a2b8 !important;
}

.wizard-prev-button {
	color: #dc3545 !important;
	background-color: transparent !important;
	border-color: #dc3545 !important;
}

.wizard-success-button {
	color: #fff !important;
	background-color: #28a745 !important;
	border-color: #28a745 !important;
}

.hidden {
	display: none;
}

input[type='checkbox'] {
	transform: scale(1.5);
}

span.sm-btn:active {
	filter: opacity(0.5);
}