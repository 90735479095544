.calendar-ui {
}
._message {
  font-weight: bold;
  font-size: 1.1em;
  margin: 1em 0;
  color: #6e6e6e;
}
.cntct_mngmnt {
  box-shadow: #495057;
  border: black;
  list-style: none;
  margin: 0.2rem;
  box-shadow: rgb(153 153 153 / 59%) 0px 0px 10px;
  background: rgb(255, 255, 255);
  border-radius: 3px;
}
.card:hover {
  border-color: rgb(108, 174, 204);
  border-width: 0.25rem;
}

.cal__left__tab {
  overflow: hidden;
  height: calc(100vh - 275px);
  overflow-y: auto;
  margin: 1em 0 0 0;

  ol {
    list-style: none;
    padding: 0 1%;
    margin: 0 1% 0 0;

    .loading {
      width: 100%;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 7px solid #0051ff;
        border-top-color: rgba(172, 172, 172, 0.192);
        animation: spinner 0.8s infinite linear;
      }
    }
    .empty-message {
      height: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
        color: rgba(102, 102, 102, 0.637);
        font-size: 1.2em;
        font-weight: 600;
      }
    }
    .ddcontainer {
      background-color: #fff;
      position: relative;
      display: block;
      width: 100%;
      .ddcontainerghost {
        width: 350px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(214, 214, 214, 0.61);
        padding: 0.8em;
        margin: 0.5em 0;
        cursor: grab;
        border-radius: 3px;
      }
      .ddcontainersource {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 0 10px rgb(180, 175, 175);
        padding: 0.8em;
        margin: 0.5em 0;
        cursor: grab;
        border-radius: 3px;
      }
      .title {
        width: 100%;
        font-weight: 600;
        font-size: 0.9em;
        margin: 0;
      }

      .address {
        width: 100%;
        color: #999;
        font-size: 0.8em;
        color: rgb(0, 107, 221);
        margin: 0;
      }
      .type {
        width: 100%;
        font-size: 0.8em;
        //color: rgb(0, 107, 221);
        margin: 0;
      }
      .date {
        font-size: 0.8em;
        //color: rgb(0, 123, 255);
        margin: 0;
        width: 100%;
      }
    }
  }
}

.arrow {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

._date_picker {
  padding: 0.5em;
  width: 100%;
  border-radius: 5px;
  background: transparent;
  transition: 0.5s;
  border: 1px solid #ced4da;
  color: #495057;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
}

@media only screen and (max-width: 1000px) {
  .ddcontainer {
    .ddcontainerghost {
      width: 100px;
    }
  }
}
