._multiselect_disabled {
	span,
	svg,
	input,
	.css-xb97g8,
	.css-tlfecz-indicatorContainer {
		display: none;
	}
	filter: opacity(0.7);
	div {
		cursor: not-allowed;
	}
}

._tbl_wrapper {
	overflow: hidden;
	overflow-x: auto;

	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		border-radius: 25px;
	}
}
._lbr_price_list_tbl {
	width: 1200px;
	margin: auto;
	thead {
		tr {
			th {
				padding: 1em;
			}
		}
	}
	td {
		padding: 0 1em;
		width: auto;
	}
}
select.form-control {
	text-transform: capitalize;
	text-align: center;

}

.__msg {
	color: rgb(82, 82, 82);
	font-weight: bold;
}
._suppliers-list {
	overflow-y: auto;
	height: 100%;
	li {
		border-left: 2px solid transparent;

		display: flex;
		justify-content: space-between;
		padding: 0.5em 1em;
		font-weight: bold;
		cursor: pointer;
		transition: border 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
		&.active {
			border-left-color: #0979fa;
			color: #0979fa;
			&:hover {
				border-left-color: #0979fa;
			}
		}
		&:hover {
			border-left-color: #999;
		}
		&.closed {
			filter: opacity(0.5);
		}
	}
}
._tbl_supp {
	width: 100%;
	border-collapse: collapse;
	thead {
		tr {
			text-align: center;
			th {
				&:not(:last-child):not(:first-child) {
					border-top: 1px solid #111;
					border-bottom: 1px solid #111;
				}
				&:not(:last-child):not(:first-child):not(:nth-child(6)) {
					border-right: 1px solid rgba(17, 17, 17, 0.383);
					box-shadow: 4px 6px 0px 0px #565656;
					background: #fff;
				}
				&:nth-child(6) {
					background: #fff;

					border-right: 1px solid #111;
					box-shadow: 4px 6px 0px 0px #565656;
				}
				&:nth-child(2) {
					border-left: 1px solid #111;
				}

				&:first-child {
					width: 80px;
					button {
						float: left;
					}
				}
				&:last-child {
					min-width: 120px;
				}
			}
		}
	}
	tbody {
		&::before {
			content: '';
			display: table-row;
			height: 20px;
		}
		tr {
			td {
				button {
					margin-top: -1em;
				}
				div {
					margin-right: 1em;
				}
				&:last-child {
					min-width: 160px;
					button {
						&:first-child {
							margin-right: 1em;
						}
					}
				}
			}
		}
	}
}

._left_panel {
	width: 230px !important;
}
._row {
	position: relative !important;
}
._suppliers {
	width: inherit !important;
	height: calc(100vh - 262px) !important;
	// border: 1px solid #999;
	position: static;
	margin-top: 0.5em;
	bottom: 0.5em;
}

.supp_date_picker_wrap {
	display: flex;
	align-items: center;
	position: relative;

	&._noMargin {
		.s_date_picker {
			margin-left: 0;
		}
	}

	position: relative;
	.s_date_picker {
		margin-left: 0.5em;
		display: block;
		width: 100%;
		height: calc(1.5em + 0.75rem + 2px);
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

		&:focus {
			color: #495057;
			background-color: #fff;
			border-color: #80bdff;
			outline: 0;
			box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		}
	}
	.ico {
		margin-left: 0.8em;
		font-size: 1.5em;
	}

	._picker_wrapper {
		position: relative;
	}

	._clear_btn {
		position: absolute;
		right: 0;
		top: 3px;
		height: 2em;
		border-radius: 50%;
		width: 32px;
		border: none;
		background: transparent;
		transition: 0.5s ease;

		&:hover {
			background: rgba(#d1d1d1, 0.4);
			transition: 0.5s ease;
		}
		&:active {
			filter: opacity(0.5);
		}
		&:disabled {
			display: none;
		}
	}
}

._sup_tabs {
	.nav-tabs {
		box-shadow: rgb(17 17 26 / 10%) 0px 1px 10px
	}
	.nav-tabs .nav-link.active {
		color: #495057;
		background-color: #fff;
		box-shadow: rgb(17 17 26 / 10%) 0px 1px 10px
	}
	position: absolute;
	left: 245px;
	border: none !important;
	width: calc(99% - 245px);
	height: calc(100vh - 130px);
	overflow: hidden;
	box-shadow: rgb(224 224 224) 0px 20px 30px;
	._cont_tab {
		border-top: none;
		height: calc(100vh - 173px);
		overflow-x: auto;
	}
}

._suppliers_btns {
	width: inherit !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 155px !important;
	border: none !important;
	._btns {
		height: 125px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		button {
			font-size: 0.9em;
			height: inherit;
			background: transparent;
			border: 1.5px solid #dee2e6;
			border-radius: 0;
			outline: none;

			&.active {
				outline: none;
				border: 2px solid #0979fa;
				background: #0979fa;
				color: #fff;
				border-radius: 0;
			}
			&:active {
				filter: opacity(0.5);
			}
		}
	}
}

._search {
	height: 30px;
	margin: 0.3em 0;
	input {
		width: 100%;
		height: inherit;
		border: 1.5px solid #dee2e6;
		padding: 0 0.4em;
		transition: border 0.5s;
		text-align: center;

		&:focus {
			color: #495057;
			background-color: #fff;
			border-color: #80bdff;
			outline: 0;
			box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
		}
		&:disabled {
			background-color: #e9ecef;
			opacity: 1;
		}
	}
}

._tab_msg {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-top: 5%;
	align-items: center;
	._msg {
		max-width: 400px;
		p {
			color: rgb(124, 124, 124);
		}
	}
}

@media only screen and (max-width: 982px) {
	._form_top {
		justify-content: space-between;
		div {
			&:first-child {
				order: 0;
			}
			&:nth-child(2) {
				order: 3;
			}
			&:nth-child(3) {
				order: 4;
			}
			&:last-child {
				order: 5;

				button {
					float: right;
				}
			}
		}
	}
}
.form-check-input[type=checkbox] {
	border-radius: 0.25em;
	transform: scale(1.1);
	border-width: 2px;
		border-color: gray;
}
@media (max-width: 820px) {
	.typelabel {
		font-size:0.8em !important
	}
	.materialListFeild{
		margin-bottom: 15px;
	}
	.subDataField{
		margin-bottom: 15px;
	}
}

// rgb(224 224 224) 0px 20px 30px;
// 	    box-shadow: rgb(17 17 26 / 10%) 0px 1px 10px;