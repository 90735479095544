.wrapper {
	margin: 20px 10px 0 10px;
}
._tab {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 0 10px #99999996;
	background: #fff;
}
._tab ._tabItem {
	width: 100%;
	text-align: center;
	font-weight: bold;
	padding: 24px 10px;
	border-radius: 2px;
}

._tabItem:nth-child(2) {
	border-left: 1px solid #9993;
	border-right: 1px solid #9993;
}

._tabItem:active,
._tabItem:focus,
._tabItem.active {
	border-bottom: 4px solid #007bff;
	transition: 0.3s;
	color: #007bff;
}

._tabItem:hover {
	transition: 0.3s;
	color: #007bff;
}

@media only screen and (max-width: 600px) {
	._tab {
		flex-direction: column;
	}
}
