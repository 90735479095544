
.body_stripe {
        text-align: center;
        padding: 40px 0;
        background: #EBF0F5;
        height: 100vh;
      }
        .stripe_h1 {
          color: #88B04B;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-weight: 900;
          font-size: 40px;
          margin-bottom: 10px;
        }
        .stripe_p1 {
          color: #404F5E;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-size:20px;
          margin: 0;
        }
      .checkmark {
        color: #9ABC66;
        font-size: 100px;
        line-height: 200px;
        margin-left:-15px;
      }
      .card_stripe {
        background: white;
        padding: 60px;
        border-radius: 4px;
        box-shadow: 0 2px 3px #C8D0D8;
        display: inline-block;
        margin: 0 auto;
      }

    //   #payment-element{
        
    //   }

    //   .PaymentElement-cardNumber {
    //     display: none;
    //   }

    .payment-element-container {
        background: #404F5E;
        height: 10rem;
    }