._message {
	font-weight: bold;
	font-size: 1.1em;
	margin: 1em 0;
	color: #6e6e6e;
}

.cntct_mngmnt {
	box-shadow: #495057;
	border: black;
	list-style: none;
	margin: 0.2rem;
	box-shadow: rgb(153 153 153 / 59%) 0px 0px 10px;
	background: rgb(255, 255, 255);
	border-radius: 3px;


}

.card:hover {
	border-color: rgb(108, 174, 204);
	border-width: 0.25rem;
}

.project_side_tab {
	overflow: hidden;
	height: calc(115vh - 245px);
	overflow-y: auto;
	// margin: 1em 0 0 0;

	a {
		margin: 0 1% 0 0;


		.loading {
			width: 100%;
			height: 300px;
			display: flex;


			.icon {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				border: 7px solid #0051ff;
				border-top-color: rgba(172, 172, 172, 0.192);
				animation: spinner 0.8s infinite linear;
			}
		}

		.empty-message {
			height: 300px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			div {
				color: rgba(102, 102, 102, 0.637);
				font-size: 1.2em;
				font-weight: 600;
			}
		}
	}
}

.arrow {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

._date_picker {
	padding: 0.5em;
	width: 100%;
	border-radius: 5px;
	background: transparent;
	transition: 0.5s;
	border: 1px solid #ced4da;
	color: #495057;

	&:focus {
		color: #495057;
		background-color: #fff;
		border-color: #80bdff;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}

	&:disabled {
		background-color: #e9ecef;
		opacity: 1;
	}
}


.item_card_with_border {
	border-left-color: "#0000e6";
	border-left-width: "7px";
	border-right-color: "transparent";
	border-top-color: "transparent";
	border-bottom-color: "transparent";
	text-align: "left";
	background-color: "transparent";
  }
  
.item_card {
	border: "none";
	background-color: "transparent";
	text-align: "left";
  }

@media only screen and (max-width: 600px) {
	.card_main {
		color: black;
	}
}