.custom-ccard {
    width: 300px;
    height: 180px;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-card-content {
    text-align: center;
  }
  
  .custom-card-content p {
    font-size: 1rem;
    color: #333;
  }

  .custom-card-content img {
    height: 4rem;
    width: 4rem;
  }
  

  .add-payment-btn {
    width: 300px;
  }
  