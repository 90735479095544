.weather-card {
    margin: 1rem;
    margin-left: 0;
    padding: 1rem;
    display: "flex";
    justify-content: "space-between";
    background-color: #caf0f8;
    border-radius: 1rem;
    flex-direction: "column";
    min-height: 11rem;

    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}