@media (max-width: 820px) {
  .monthly_sub_label {
    font-size: 0.9em !important;
  }
}
.table > :not(:first-child) {
  border-top: 1px solid #cec0c0 !important;
}

.no-scrollbar {
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.contact-table {
  * {
    padding: 0.5rem 0.5rem !important;
  }
}
.react-bootstrap-table-page-btns-ul {
  float: left !important;
  margin-top: 0 !important;
}

.table-header {
  text-align: left;
}

.pagination-btn {
  ul {
    position: relative !important;
    margin: 0;
    li {
      margin: 0;
    }
  }
}
