@import "../../variables";
.spinner {
  animation: 0.75s linear infinite spinner-border;
}

.proposal-grp-lablel {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 5px 5px;
  color: #999;
}

.sm-btn {
  background-color: $PRIMARY_COLOR;
  margin-left: 2em;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}

.__quote_new_tab {
  ul {
    margin-top: 1.5em;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: whitesmoke;
    border-radius: 30px;
    position: relative;
    transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &:hover {
      background-color: transparent;
    }
    li {
      text-align: center;
      font-weight: bold;
      padding: 0.8em 0;
      width: 100%;
      transition: 0.11s;
      cursor: pointer;
      &.active:hover,
      &.active {
        background-color: $PRIMARY_COLOR;
        color: #fff;
      }
      &:hover {
        background-color: #999;
      }

      border-radius: 30px;
    }
  }
}

.__quote_screen {
  flex: 1;
  display: flex;
  margin-bottom: 1em;
}
.__quote_proposals {
  width: 155px;
  margin-right: 1em;
}
.__quote_estimator {
  flex: 1;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  .nav-tabs {
    box-shadow: rgba(17, 14, 14, 0.1) 0px 1px 3px;
    // border-color: #999;
    .nav-link.active {
      color: #495057;
      background-color: #fff;
      box-shadow: rgb(17 17 26 / 10%) 0px 1px 3px;

      // border-color: #999 #999 transparent;
    }
  }
  .tab-content {
    box-shadow: rgb(153 153 153 / 59%) 0px 0px 10px;
    border-top: none;
    padding: 0 0.85rem;
    height: calc(100% - 2.4em);
  }
}

.__quote_proposals_list {
  box-shadow: rgb(153 153 153 / 59%) 0px 0px 10px;
  width: 100%;
  height: calc(100% - 2.4em);
  margin-top: 1em;

  ul {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 37px;
      padding: 0.5em 1em;
      font-weight: bold;
      cursor: pointer;
      transition: border 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &.active {
        border-left: 2px solid #0979fa;
        color: #0979fa;
        &:hover {
          border-left: 2px solid #0979fa;
        }
      }
      &:hover {
        border-left: 2px solid #999;
      }
      &.closed {
        filter: opacity(0.5);
      }
    }
  }
}

.__quote_top_btns {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  // a {
  //   button {
  //     display: block;
  //     margin-bottom: 1em;
  //     margin-left: 0.5em;
  //     height: 45px;
  //     width: 160px;
  //   }
  // }
  button {
    display: block;
    margin-bottom: 1em;
    margin-left: 0.5em;
    height: 45px;
    width: 150px;
  }
}

.__quote_pricing_list {
  padding: 0 2em;
  max-height: 375px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 1.5em;
  ul {
    list-style: none;
    padding: 0;
    li {
      width: 100%;
      button {
        width: 100%;
        padding: 0.5em;
        display: flex;
        justify-content: space-between;
        background: transparent;
        border: none;
        transition: background-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out;

        &:hover {
          background-color: #e9ecef5d;
        }
        &:disabled {
          background-color: #e9ecef;
          opacity: 1;
          span {
            filter: opacity(0.5);
          }
        }
        &:active {
          outline: none;
          filter: opacity(0.5);
        }
      }
    }
  }
}
.__quote_series_txt {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-align: center;
  outline: none;
  // margin-bottom: 1em;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
  &:active {
    outline: none;
    filter: opacity(0.5);
  }
}
.__menu_left_quote {
  bottom: unset;
  margin-bottom: 1em;
  height: inherit !important;
  min-height: calc(100vh - 163px) !important;
}

div#controlled-tab-example-tabpane-001 {
  &.show {
    max-height: unset !important;
  }
}

.__tab_quote {
  height: auto !important;
  overflow: unset !important;
  margin-bottom: 4em !important;
}

.__cont_quote {
  min-height: calc(100vh - 173px);
  height: auto !important;
  margin-bottom: 1em;
}

._quote_cont {
  padding: 1em;
  // min-height: 160px;
}

.__quote_tbl_wrapper {
  max-width: 100%;
  overflow-x: scroll;
}

.__quote_tbl {
  margin: 1em 0;
  width: 1200px;
  th,
  td {
    text-align: center;
  }
  td {
    width: 200px;
    height: 65px;
  }
  td:nth-child(3) {
    white-space: pre-wrap;
  }
}

@media only screen and (max-width: 1024px) {
  .__quote_estimator_head {
    flex-direction: column;

    .col-lg-6.col-md-12 {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .__quote_top_btns {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    button {
      font-size: 0.8rem;
      display: block;
      margin-bottom: 1em;
      margin-left: 0.5em;
      height: 45px;
      width: 145px;
    }
  }
}
