.__proposal_tab_select_modal {
  max-height: 482px;
  overflow: hidden;
  overflow-y: auto;
}

.__proposal_tab_drag_data {
  display: flex;
  justify-content: space-between;
  margin: 0.5em 0;
  align-items: center;
  padding: 0.5em 0;
  .inp {
    flex-grow: 1;
    margin: 0 1.5em;
  }
  &.sortable-chosen.sortable-ghost {
    background: rgba(214, 214, 214, 0.192);
    border-top: 1px dashed rgb(202, 202, 202);
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}
.modal_body {
  height: 60vh !important;
}

.__proposal_tab_stepper {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}

// .modal-content{
//   width:40rem !important;
// }
