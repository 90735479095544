input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.buildingSubhead {
  text-align: center;
  font-weight: bold;
  font-size: 0.9em;
  padding-bottom: 1em;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="radio"] {
  cursor: pointer;
}
._checkmark {
  width: 18px;
  height: 18px;
}
.modal-90w {
  width: 100%;
}
button.modified_btn {
  height: 50px;
  min-width: 100px;
  margin: 0 0.5em 0.5em 0.5em;
}
button.modified_btnBuilding {
  height: 45px;
  width: 75px;
  margin: 0 0.5em 0.5em 0.5em;
}

.ele_grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(120px, 120px));
  justify-content: flex-start;
}
.eledetail_grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(120px, 120px));
  justify-content: flex-start;
}
._head_tableGutters {
  background: transparent;
  color: #111;
  text-align: left;
}

table._tbl_building {
  width: 100%;
  margin-bottom: 0.3em;

  thead {
    tr {
      background: rgba(200, 200, 200, 0.301);
      border: 1px solid #111;
    }
    th:nth-child(2) {
    }
    th {
      width: 93.6px;
    }
  }
}
._body_tableGutters,
._body_table2 {
  tr {
    text-align: left;
  }
}
._body_tableGutters {
  tr {
    text-align: left;

    &:hover {
      background: rgba(206, 206, 206, 0.103);
    }
  }
}
button.tblDel {
  &:disabled {
    display: none;
  }
}
.btn-primary {
  min-width: unset;
}
.metric {
  margin: 2em 2em;
}
.btnheading {
  color: rgba(41, 40, 40, 0.911);
  font-weight: 800;
  font-size: 1.2em;
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}

.gutterheading {
  text-align: center;
  width: 100%;
}
.ClickableBtnModal {
  padding: 1.5em 1em;
}

// .ClickableBtn {
// 	padding: 0.6em;
// }

.ClickableBtnModal,
.ClickableBtn {
  font-weight: bold;
  font-size: large;
  min-width: 100px;
  margin: 0 0 0.3em 0.3em;
  transition: 0.7s;
  background: transparent;
  color: rgba(41, 40, 40, 0.911);
  border: 1px solid rgba(200, 200, 200, 0.548);
  border-radius: 5px;
  background: rgba(200, 200, 200, 0.548);
  height: 3.6rem;

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    transform: scale(1);
    color: #999;
  }

  &.selected {
    color: #fff;
    background: #007bff;
    border: 1px solid #007bff;
  }
}

.numpad {
  display: grid;
  grid-template-columns: repeat(4, 82px);
  row-gap: 1em;
  column-gap: 1em;
  margin: 1.5em 1em;
  justify-content: center;
  .numpad-btn {
    font-weight: bold;
    border: 1.5px solid rgba(29, 26, 26, 0.753);
    color: rgba(29, 26, 26, 0.876);
    background: transparent;
    font-size: 1.2em;
    border-radius: 5px;
    width: 85px;
    height: 70px;

    &.clear {
      background: red;
      color: #fff;
      border-color: red;
    }
    &.add {
      background: rgb(0, 89, 255);
      color: #fff;
      border-color: rgb(0, 89, 255);
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 5;
      height: auto;
    }

    &.save {
      background: rgb(17, 145, 77);
      color: #fff;
      border-color: rgb(17, 145, 77);
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 5;
      height: auto;
    }

    &.save:hover,
    &.save:focus {
      background: rgba(17, 145, 77, 0.76);
      color: #fff;
      border-color: rgba(17, 145, 77, 0.76);
    }

    &.zero {
      grid-column-start: 2;
      grid-column-end: 4;
      grid-row-start: 4;
      grid-row-end: 4;
      width: auto;
    }
    &:active,
    &.add:active,
    &.save:active,
    &.clear:active {
      filter: opacity(0.5);
    }

    &:hover,
    &:focus {
      background: rgba(200, 200, 200, 0.548);
    }

    &.add:hover,
    &.add:focus {
      background: rgba(0, 89, 255, 0.76);
      color: #fff;
      border-color: rgba(0, 89, 255, 0.76);
    }
    &.clear:hover,
    &.clear:focus {
      background: rgba(255, 0, 0, 0.76);
      color: #fff;
      border-color: rgba(255, 0, 0, 0.76);
      filter: blur(0.5);
    }
    &:disabled:hover,
    &:disabled {
      background: transparent;
      border-color: #999;
      color: #999;
    }
  }
}
._lbl,
._txt {
  font-weight: bold;
  font-size: larger;
  text-align: center;
}
.tabposition {
  margin-left: 25px;
}
._block {
  padding-top: 0.2em;
  width: 110px;
  height: 70px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border: 2px solid #111;
  background: rgba(200, 200, 200, 0.548);
  align-items: center;

  border-radius: 5px;
  font-weight: bold;
  font-size: 1.1em;

  ._block-lbl,
  ._block-txt {
    padding: 0;
    margin: 0;
  }

  &.selected {
    background: rgb(0, 89, 255);
    color: #fff;
    cursor: pointer;
    border-color: rgb(0, 89, 255);
  }
  &:disabled,
  &.unselected {
    cursor: pointer;
    background: transparent;
    color: #999;
    border-color: #999;
  }
}
.icon-btw {
  margin: 0 1em;
}

table.dimen_table {
  width: 100%;
  font-size: larger;
  th {
    text-align: center;
    padding: 1em 0;
  }
  thead {
    tr {
      display: flex;
    }

    th {
      flex: 1;
    }
  }
  tbody {
    tr {
      display: flex;
      td {
        text-align: center;
        padding: 1em 0;
        flex: 1;
      }
      &:nth-child(even) {
        background: rgba(200, 200, 200, 0.301);
      }
    }
  }
}

._carsl {
  position: relative;
  overflow: hidden;
}
.slide-left {
  background: #fff;
  animation: slideRight 0.7s both;
}
.slide-right {
  background: #fff;
  animation: slideLeft 0.7s both;
}

.guttertype {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@keyframes slideLeft {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
._head_tableGutters,
._head_table {
  text-align: center;
}
._body_tableGutters,
._body_table2 {
  border-top: 1px solid currentColor !important;
  // .table>:not(:first-child) {
  // 		border-top: 0px solid currentColor !important;
  // 	}
  tr {
    td {
      text-align: center;
    }
    td:nth-child(4) {
      white-space: pre-wrap;
      text-align: center;
    }
  }
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 !important;
}
.totalData {
  font-weight: bold;
  font-size: 1.2em;
}

.pagination_btns {
  background: transparent;
  border: none;
  padding: 0.5em;
  color: blue;
  font-size: 2em;
  &:disabled {
    color: #999;
  }
}
.page_num {
  font-size: 2em;
  margin-top: unset;
  margin-bottom: unset;
  margin: 0 0.5em;
  font-weight: bold;
}
@media (max-width: 820px) {
  .ClickableBtn {
    font-size: 0.8em;
    max-width: 20%;
  }
  .openbtn {
    color: "black";
    width: 100%;
  }
  .titlegutter {
    word-wrap: break-word !important;
  }
  .Removebtn {
    min-width: 150% !important;
  }
  .guttertype {
    flex-wrap: wrap !important;
  }
}
