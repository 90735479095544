.slides-btns {
	margin: 2em auto;
	max-width: 700px;
	button.move {
		background: transparent;
		border: none;
		img {
			height: 45px;
		}
		&:active {
			filter: opacity(0.5);
		}
		&:disabled {
			filter: opacity(0.5);
		}
	}
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.slides {
	.img {
		overflow: hidden;
		padding-top: 1em;
		.ref-pic {
			display: flex;
			font-weight: bold;
		}
		img {
			margin: 0 auto;
			// max-height: 500px;
			position: relative;
			display: block;
			max-width: 100%;
			// object-fit: cover;
			animation: slide-left 0.5s;
		}
	}
}

@keyframes slide-left {
	from {
		filter: blur(3px);
		opacity: 0;
	}
	to {
		filter: blur(0px);
		opacity: 1;
	}
}
