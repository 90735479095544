@media (min-width: 1200px) {
	.modal-2xl {
		max-width: 1240px !important;
		margin: 1.75rem auto !important;
	}
}
@media (min-width: 992px) {
	.modal-2xl {
		max-width: 1107px;
		margin: 1.75rem auto;
	}
}

@media (min-width: 576px) {
	.modal-dialog.modal-2xl {
		max-width: 1107px;
		margin: 1.75rem;
	}
}
