@tailwind base;
@tailwind components;
@tailwind utilities;

a,
a:hover {
  text-decoration: none;
  color: inherit;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
*:disabled {
  cursor: not-allowed;
}

*::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
*::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, 0.1);
}
// * {
// 	scrollbar-color: rgba(0, 0, 0, 0.2) hsla(0, 0%, 100%, 0.1);
// 	scrollbar-width: thin;
// }

.loading {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 7px solid #0051ff;
    border-top-color: rgba(172, 172, 172, 0.192);
    animation: spinner 0.8s infinite linear;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rccs {
  margin: 0 !important;
}