.serviceRequestBtn {
	font-weight: bolder;
	padding: 1em;
	margin: 1em;
	width: 180px;
	transition: 0.7s;
	background: transparent;
	color: rgba(41, 40, 40, 0.911);
	border: 1px solid #999;
	border-radius: 5px;
}
.serviceRequestBtn:hover {
	transform: scale(1.1);
}

.serviceRequestBtn.selected {
	color: #fff;
	background: #007bff;
	border: 1px solid #007bff;
}

.left-text {
	text-align: left;
}

.buildings-card {
	border-top: 1px solid rgba(214, 214, 214, 0.644);
	padding: 1em 0;
	margin-top: 1em;
}

.buildings-list {
	list-style: none;
	padding: 0;
}

.buildings-list .coop-units {
	border-top: 1px solid rgba(214, 214, 214, 0.644);
	border-bottom: 1px solid rgba(214, 214, 214, 0.644);
	padding: 1em;
}

// .buildings-list .coop-units:nth-child(even) {
// 	background: rgba(0, 0, 0, 0.05);
// }

// .buildings-list li:hover {
// 	background: rgba(0, 0, 0, 0.05);
// }

.addRemoveBtn {
	border: none;
	display: inline-block;
	padding: 3px;
	background: transparent;
	font-size: 14px;
	margin: 0 5px;
}
.addRemoveBtn.remove {
	color: #990000;
}
.addRemoveBtn.add {
	color: #008800;
}
button.addRemoveBtn:disabled {
	color: #999;
	display: none;
}

.div {
	overflow: hidden;
	max-height: 0;
	transition: all 0.3s;
}
._show {
	max-height: 1000px;
	transition: all 1s;
}

.edit-btn {
	margin-right: 0.5em;
}

.dlt-btn {
	margin-left: 0.5em;
}

.table {
	._body_table1 {
		white-space: pre-line;
		text-align: center;
		tr:nth-child(even) {
			background: rgba(0, 0, 0, 0.05);
		}
	}

	.thead {
		color: #111;
		background-color: transparent;
		tr {
			th {
				&:hover:nth-child(n) {
					text-align: center;
					background-color: #a8dadc;
					border-width: 1px !important;
				}
				&:nth-child(2) {
					text-align: center;
					border-width: 1px !important;
				}
				&:nth-child(3) {
					text-align: center;
					border-width: 1px !important;
				}
				&:nth-child(4) {
					text-align: center;
					border-width: 1px !important;
				}
				&:nth-child(5) {
					text-align: center;
				}
			}
		}
	}

	td:nth-child(1) {
		text-align: center;
	}
}
