.calendar-events-list-enter {
	opacity: 0;
	transform: scale(0.9);
}
.calendar-events-list-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 300ms, transform 300ms;
}
.calendar-events-list-exit {
	display: none;
}
.calendar-events-list-exit-active {
	display: none;
}

.empty {
	background-color: #9f9c9c5e;
	opacity: 0.4;
}

button {
	cursor: pointer;
}

.btns-group {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.today-btn {
		background: transparent;
		border: 2px solid rgb(0, 123, 255);
		color: rgb(0, 123, 255);
		padding: 0.8em 3em;
		font-weight: bolder;
		font-size: 1em;
		transition: 0.5s;

		&.inverted {
			background: #0dcaf0;
			color: #fff;
		}
		&.selected,
		&.inverted:active,
		&.inverted:hover {
			background: transparent;
			border: 2px solid rgb(0, 123, 255);
			color: rgb(0, 123, 255);
		}

		&:hover {
			background: #0dcaf0;
			color: #fff;
		}
	}
}

tr:hover {
	background: rgba(212, 212, 212, 0.15);
}

._heading {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	// .month-name {
	// 	font-size: 2em;
	// }

	button {
		background: transparent;
		border: none;

		img {
			height: 35px;
			width: 35px;
		}
	}
}

.calendar-day {
	position: relative;
	overflow: hidden;
	&.clickable {
		cursor: pointer;
	}

	span {
		transition: 0.5s;
		position: absolute;
		right: 15px;
		top: 10px;
		color: rgb(151, 144, 144);

		&:hover {
			color: rgb(82, 82, 82);
		}
	}
	._badge {
		position: absolute;
		left: 15px;
		top: 10px;
		border-radius: 50%;
		background: rgba(192, 65, 150, 0.883);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1em;
		color: #fff;
		font-weight: bold;
		padding: 5px 8px;
		height: 2em;
		width: 2em;
	}
	&.today {
		background: rgba(9, 139, 179, 0.164);

		span {
			font-size: 1.5em;
			color: rgb(165, 11, 157);
		}
	}

	&.selected {
		background: #ff73f529;
	}
	.dots {
		position: absolute;
		bottom: 0;
		left: 2px;
		bottom: 2px;
		height: 20px;
		width: 70%;
		overflow: hidden;
	}
	.more-text {
		position: absolute;
		bottom: 0.6em;
		color: #999;
		font-weight: bold;
		font-size: 0.8em;
		right: 2px;
		padding: 0 0.2em;
		background: #fff;
	}
}

.event-detail-badge,
.dot {
	display: inline-block;
	border-radius: 50%;
	width: 10px;
	height: 10px;
	margin: 2px;
	bottom: 0;
	border: none;
	box-shadow: inset 1px 0px 4px 0px #00000054;
}
.event-detail-badge {
	margin: 0 10px;
	width: 15px;
	height: 15px;
}

._calendar_table_month {
	min-height: calc(100vh - 260px);
	height: calc(100vh - 260px);
}
._calendar_table_week {
	height: auto;
}

._calendar_table_week,
._calendar_table_month {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid #99999947;

	._week_view_td {
		border: none;
		width: calc(100% / 7);
		overflow: hidden;
		position: relative;
		span.droptarget {
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}
	}
	td {
		span.droptarget {
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
		}
		border: 1px solid #99999947;
		height: 65px;
		padding: 0;
		margin: 0;
		min-width: 40px;
		&:last-child.calendar-day,
		&:nth-child(1).calendar-day {
			._num_date {
				color: rgba(240, 2, 2, 0.603);
			}
		}
		.week-event {
			border-radius: 5px;
			font-size: 0.8rem;
			padding: 0.5rem 0.5rem;
			margin: 0.2rem 0.2rem;
			cursor: pointer;
			color: #fff;
		}
	}

	thead {
		tr {
			._week_view_th {
				border: none;
				text-align: center;
			}

			th {
				border: 1px solid #99999947;
				padding: 0.5rem 0;
				color: #111;
				cursor: pointer;
				text-align: center;
				height: 41px;

				.date {
					font-weight: 300;
					font-size: 0.9rem;
				}
				&.today {
					background: rgba(6, 175, 226, 0.08);
					.date {
						color: rgba(3, 136, 3, 0.733);
					}
				}

				&:hover {
					background: rgba(202, 202, 202, 0.192);
				}

				&:nth-child(1),
				&:last-child {
					color: rgba(240, 2, 2, 0.603);
				}
			}
		}
	}
}

tr {
	width: 100%;
}

.day-view {
	border: 1px solid rgba(212, 212, 212, 0.582);
	position: relative;
	max-width: 100%;
	overflow-y: hidden;
	padding-bottom: 0.035rem;

	._day_events {
		position: absolute;
		left: 75px;
		width: calc(100% - 76px);
		right: 1px;
		height: 100%;
		top: 0;
	}

	.day-event {
		padding: 0;
		position: absolute;
		overflow: hidden;
		z-index: 0;
		&:hover {
			z-index: 100;
			cursor: pointer;
		}
		span.text {
			margin: 15px 0 0 4px;
			font-weight: 300;
			color: rgb(73, 73, 80);
		}
		span {
			position: absolute;
			font-weight: 600;
			font-size: 0.8rem;
		}
		span:nth-child(1) {
			top: 0px;
			left: 4px;
		}
		span:nth-child(3) {
			bottom: 0px;
			right: 4px;
		}
	}

	.hour {
		z-index: 1;
		height: 60px;
		&:nth-child(odd) {
			background: rgba(223, 223, 223, 0.198);
		}
		&:nth-child(1) {
			outline: none;
			height: 60px;
		}
		outline: 1px dashed rgba(212, 212, 212, 0.582);
		height: 60px;
		.hour-space {
			height: 30px;
			padding: 0;
			span {
				font-size: 0.9rem;
				margin: 1rem;
				font-weight: 600;
				color: rgb(95, 95, 95);
			}
			&:nth-child(2) {
				height: 30px;
				outline: 1px dashed rgba(212, 212, 212, 0.582);
			}
			&:nth-child(1) {
				height: 30px;
			}
			&:hover {
				background: rgba(202, 202, 202, 0.192);
			}
		}
	}
}
.events-display {
	background-color: #7689de;
	box-shadow: inset 0 0 15px 0 rgba(0, 0, 0, 0.5);
	color: #fff;
	// transition: animation 0.45s;
	// animation: fadeIn 0.45s;
}
.displayed-event {
	padding: 5px 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 0.5rem;
	&:nth-child(1) {
		padding-top: 1rem;
	}
	&:last-child {
		padding-bottom: 1rem;
	}
}

@media only screen and (max-width: 1360px) {
	._calendar_table {
		td.calendar-day {
			max-height: 67px;
		}
	}
	.calendar-day {
		span {
			transition: 0.5s;
			position: absolute;
			right: 15px;
			top: 10px;
			color: rgb(151, 144, 144);
		}
		._badge {
			top: initial;
			left: 4px;
			height: 25px;
			width: 25px;
			bottom: 4px;
		}

		.dots {
			display: none;
		}

		.more-text {
			display: none;
		}
	}

	.btns-group {
		.today-btn {
			padding: 0.8rem 1.5rem;
		}
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 0.2;
	}
	40% {
		opacity: 0.4;
	}
	60% {
		opacity: 0.6;
	}
	80% {
		opacity: 0.8;
	}
	100% {
		opacity: 100;
	}
}
