.upload-progress {
	width: 100%;
	background: #999;
	height: 5px;
	margin-top: 1.5em;
	div {
		background: black;
		height: 100%;
		transition: 0.5s width cubic-bezier(0.4, 0, 1, 1);
	}
}

.react-bootstrap-table table {
	table-layout: unset !important;
}
.addBtn {
	border: none;
	font-size: 3em;
	background: transparent;
	width: 100%;
	&:active {
		filter: opacity(0.5);
	}
}
.selected-imgs-group {
	display: grid;
	grid-template-columns: repeat(5, minmax(100px, 140px));
	grid-column-gap: 1em;
	justify-content: space-between;
	div {
		&:not(:last-child) {
			margin-right: 1em;
		}
	}
}

.img-prev-wrap {
	position: relative;
	width: 100%;
	.img-preview {
		width: 100%;
		display: block;
		border: 1px dashed grey;
		height: 80px;
		border-radius: 5px;
		background-size: cover !important;
		background-position: center !important;
		cursor: pointer;
	}
	.check-img {
		width: 27px;
	}
	.check-img,
	.delete-img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		border: none;
		font-size: 1em;
	}
	.check-img {
		position: absolute;
		left: -4px;
		top: -12px;
		background: #07ad62;
		color: #fff;
	}
	.delete-img {
		position: absolute;
		right: -10px;
		font-size: 1.1em;
		top: -12px;
		background: red;
		color: #fff;
		&:active {
			filter: opacity(0.5);
		}
	}
}
.images_tbl_body {
	td {
		text-align: center !important;
		font-weight: 500;
		border: none !important;
		&:nth-child(3) {
			white-space: pre-wrap;
		}
		&:last-child {
			max-width: 100%;
		}
	}
	border: none !important;
}
.images_tbl {
	width: 30px;
	margin: 3em 0 1em 0;
	border: none !important;
	th {
		border: none !important;
		text-align: center;

		&:not(:last-child) {
			width: 150px;
		}
		&:last-child {
			max-width: 100%;
		}

		height: 50px;
	}
	th:last-child {
		// display: flex;
		align-items: center;
		&:before {
			margin-right: 1em;
		}
		&:after {
			margin-left: 1em;
		}
		&:before,
		&:after {
			content: '';
			width: 50%;
			height: 2px;
			background: #212529;
			border-radius: 25px;
		}
		span {
			margin: 0 1em;
		}
	}
	td {
		&:not(:last-child) {
			width: 15vw;
		}
	}
}

.picture {
	border: 0;
	visibility: hidden;
}

.camera,
.image-view,
.picture,
.camera-view {
	position: fixed;
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	top: 0;
	left:0;
	object-fit: cover;

	.captureBtn {
		height: 70px;
		width: 70px;
		border-radius: 50%;
		background: #fff;
		z-index: 9999;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		position: fixed;
		border: 4px solid rgba(0, 0, 0, 0.7);

		&:active {
			filter: opacity(0.5);
		}
	}
	.rotateBtn {
		background: transparent;
		border: none;
		z-index: 9999;
		bottom: 20px;
		right: 50px;
		position: fixed;
		img {
			width: 50px;
			height: inherit;
		}
		&:active {
			filter: opacity(0.5);
		}
	}
}

.backBtn {
	position: fixed;
	font-size: 2em;
	color: #fff;
	background: transparent;
	border: none;
	height: 70px;
	width: 70px;
	z-index: 9999;
	top: 20px;
	left: 20px;
	&:active {
		filter: opacity(0.5);
	}
}

.submit {
	height: 70px;
	width: 70px;
	border-radius: 50%;
	background: #07ad62;
	z-index: 9999;
	bottom: 25px;
	left: 50%;
	transform: translateX(-50%);
	position: fixed;
	border: 4px solid rgba(0, 0, 0, 0.7);
	font-size: 1.5em;
	color: #fff;
	&:active {
		filter: opacity(0.5);
	}
}

.retry {
	height: 70px;
	width: 70px;
	border-radius: 50%;
	background: red;
	z-index: 9999;
	bottom: 25px;
	left: 25px;
	position: fixed;
	border: 4px solid rgba(0, 0, 0, 0.7);
	font-size: 1.5em;
	color: #fff;
	&:active {
		filter: opacity(0.5);
	}
}

.taken {
	visibility: visible;
	height: 100% !important;
	width: 100% !important;
	transition: all 0.1s ease-in;
	border: solid 15px #999;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
	z-index: 2;
	animation: blink 0.1s 0.1s linear;
}

@keyframes blink {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.loading-wrapper-camera {
	position: fixed;
	height: 100%;
	width: 100%;
	margin: 0;
	left: 0;
	padding: 0;
	top: 0;
	object-fit: cover;
	background-color: #111;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	div:last-child {
		font-size: 1.5em;
		font-weight: bold;
		margin: 1em 0;
		color: rgb(212, 173, 173);
	}
}

@keyframes rotate360 {
	0% {
		transform: rotate(0);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loadingDot {
	height: 75px;
	border: 10px dotted #fff;
	border-radius: 50%;
	width: 75px;
	animation: rotate360 2s linear infinite;
}
.loadingDot-sm-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.warpper-error {
		color: red;
		text-align: center;
		font-size: larger;
		margin-left: 1em;
	}
	button._retry {
		margin-top: 0.5em;
		background: transparent;
		border: 1px solid #999;
		font-size: small;
		color: rgb(93, 93, 93);
		&:active {
			filter: opacity(0.5);
		}
	}
	.loadingDot-sm {
		margin-right: 0 !important;
		height: 45px;
		border: 6px solid #111;
		border-right-color: transparent;
		border-radius: 50%;
		width: 45px;
		animation: rotate360 2s linear infinite;
		animation: rotate360 2s linear infinite;
	}
}

@media (max-width: 820px) {
	.MeasurementsBtnImage{
		width: 20% !important
	}
	.modified_btn{
		width: 29%!important;
	}
}
.pagination{
	--bs-pagination-padding-x: 2.5rem !important;
		--bs-pagination-padding-y: 1rem !important;
			--bs-pagination-font-size: 1.3rem !important;
}
