.list_item-enter {
	opacity: 0;
	transform: translateX(-100%);
}
.list_item-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.list_item-exit {
	opacity: 1;
  transform: translateX(0%);
}
.list_item-exit-active {
	opacity: 0;
  transform: translateX(-100%);
}

.list_item-enter-active,
.list_item-exit-active {
  transition: opacity 500ms, transform 500ms;
}

.__proposal {
	padding: 1em;

	.__empty_block {
		height: 300px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(202, 202, 202, 0.466);
		border-radius: 15px;
		border: 2px dashed rgb(184, 184, 184);

		span {
			color: #999;
			font-weight: bold;
			padding: 0 1em;
			text-align: center;
		}
	}
}
.__reload_wrapper {
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.__reload_btn {
	background: transparent;
	border: 2px solid #111;
	padding: 0.4em 1em;
	cursor: pointer;
}

.__btn_expanded {
	width: 100%;
}
