.search-field {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 10px rgb(180, 175, 175);
    margin: .5em 0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    position: relative;
    img {
        padding-left: .8em;
        position: absolute;
        pointer-events: none;
    }

    input[type='search'] {
        width: 100%;
        background: transparent;
        border: none;
        padding: 1em .8em 1em 3em;
    }
    
}