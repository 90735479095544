._tab {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	box-shadow: 0 0 10px #99999996;
	background: #fff;
	margin: 1em 0 0.5em 0;
	border-radius: 3px;
	overflow: hidden;
	height: auto;
}
._tab ._tabItem {
	width: 100%;
	text-align: center;
	font-weight: bold;
	height: auto;
	border-radius: 3px;
	border: none;
	color: rgba(46, 45, 45, 0.801);
	border-left: 1px solid #9993;
	border-right: 1px solid #9993;
	cursor: pointer;
	background: transparent;
}

._tabItem:active,
._tabItem:focus,
._tabItem.active {
	border-bottom: 4px solid #007bff;
	transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	color: #007bff;
}

._tabItem:hover {
	transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	color: #007bff;
}
