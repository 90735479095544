._title {
  font-weight: 600;
}
.wrapper {
  margin: 20px 10px 0 10px;
}
button._btn_modified.btn.btn-primary {
  width: 100%;
}

.__title {
  position: relative;
  background: rgb(187, 187, 187);
  width: 100px;
  height: 15px;
  border-radius: 25px;
}
._data {
  margin: 1em 1em 1em 0;
  background-color: rgba(165, 165, 165, 0.335);
  width: 150px;
  height: 15px;
  border-radius: 25px;
}
._data2 {
  margin: 1em 1em 1em 0;
  background-color: rgba(153, 153, 153, 0.267);
  width: 350px;
  height: 15px;
  border-radius: 25px;
}
._btn_skeleton {
  margin-right: 1em;
  background-color: rgba(153, 153, 153, 0.281);
  width: 100px;
  height: 40px;
  border-radius: 4px;
  border-radius: 8px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    animation: shineAndSlide 0.9s ease infinite;
    transform: translateX(0) skewX(-15deg);
    transition: 0.6s;
  }
}

.empty-block {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    color: rgba(102, 102, 102, 0.637);
    font-size: 1.2em;
    font-weight: 600;
  }
}

._tab-content {
  box-shadow: 0 0 10px #99999996;
}

.date_time {
  padding: 0.8em 1em;
  display: flex;
  font-weight: 800;
  font-size: x-large;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .day_date {
    display: flex;
    flex-wrap: wrap;
    .day {
      margin: 0 1em;
    }
  }
}

._sales-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1em;
  padding: 1em 1em;

  border-top: 1px solid rgba(219, 217, 217, 0.644);
  ._btns {
    width: 160px;
  }
  .btns_groups {
    display: flex;
    flex-direction: column;
    min-height: 125px;
    width: calc(100% / 4);
    justify-content: space-between;
  }
  .p-details {
    font-weight: 600;
    width: calc(100% / 7);
    sub {
      color: rgba(17, 17, 17, 0.856);
      font-weight: 300;
      font-size: 12px;
    }
    .names {
      margin: 5px 0;
      h5 {
        font-weight: 600;
        margin: 0;
        font-size: large;
      }
    }

    p {
      line-height: 0.2;
      color: #999;
      font-size: small;
    }
    .address {
      font-weight: 700;
      cursor: pointer;
      line-height: 0.2;
      color: rgb(0, 107, 221);
      font-size: medium;
      margin-bottom: 0.5em;
    }
    .tels {
      margin: 5px 0;
      .tel {
        padding: 0;
        color: rgb(5, 102, 206);
      }
    }
  }
  .o-details {
    width: calc(100% / 5);
    margin-top: 0.5em;
    div {
      font-size: 17px;
      padding: 0;
      margin: 0;
      font-weight: 550;
      span {
        font-weight: 550;
      }
      span.bold {
        font-weight: 550;
      }
    }
    ._label,
    label {
      margin: 0;
      font-weight: 800;
    }
    .bold {
      font-weight: 900;
      font-size: 17px;
      flex-wrap: wrap;
    }
  }
}

.empty-_tab {
  padding: 1em;
}

.created-date {
  padding: 0.4em 0;
  margin-bottom: 1em;
}

._datePicker {
  padding: 0.4em;
  width: 100%;
  margin-bottom: 1em;
  border-radius: 4px;
  border: 1px solid #a09f9f80;
}

.__datePicker {
  padding: 0.4em;
  width: 100%;
  margin-bottom: 1em;
  border-radius: 4px;
  // border: 1px solid #a09f9f80;
}

@media only screen and (max-width: 600px) {
  ._tab {
    flex-direction: column;
  }
}

@keyframes shineAndSlide {
  0% {
    transform: translateX(0) skewX(-15deg);
  }
  100% {
    transform: translateX(500px) skewX(-15deg);
  }
}
