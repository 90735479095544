._title {
  font-weight: 600;
}
.wrapper {
  margin: 20px 10px 0 10px;
}
button._btn_modified.btn.btn-primary {
  width: 100%;
}

._tab-content {
  box-shadow: 0 0 10px #99999996;
  height: calc(100vh - 309px);
  overflow-y: auto;
  overflow-x: hidden;
}

._date_picker2 {
  border: none;
  // margin: 0 5px;
  padding: 1px 5px;
  max-width: 95px;
  font-weight: 550;
  font-size: medium;
  cursor: pointer;
}

.sub {
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 0.5px;
  color: rgb(90, 90, 90);
}

.date_time {
  padding: 0.8em 1em;
  display: flex;
  font-weight: 800;
  font-size: x-large;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .day_date {
    display: flex;
    flex-wrap: wrap;
    .day {
      margin: 0 1em;
    }
  }
}

.react-month-picker {
  float: right;

  input {
    padding: 0.5em;
    width: 100%;
    border-radius: 5px;
    background: transparent;
    transition: 0.5s;
    border: 1px solid #ced4da;
    color: #495057;
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    &:disabled {
      background-color: #e9ecef;
      opacity: 1;
    }
  }
}

._sales-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1em;
  padding: 1em 1em;

  border-top: 1px solid rgba(219, 217, 217, 0.644);
  ._btns {
    width: 160px;
  }
  .btns_groups {
    display: flex;
    flex-direction: column;
    min-height: 125px;
    width: calc(100% / 4);
    justify-content: space-between;
  }
  .p-details {
    font-weight: 600;
    width: calc(100% / 7);
    sub {
      color: rgba(17, 17, 17, 0.856);
      font-weight: 300;
      font-size: 12px;
    }
    .names {
      margin: 5px 0;
      h5 {
        font-weight: 600;
        margin: 0;
        font-size: large;
      }
    }

    p {
      line-height: 0.2;
      color: #999;
      font-size: small;
    }
    .address {
      font-weight: 700;
      cursor: pointer;
      line-height: 0.2;
      color: rgb(0, 107, 221);
      font-size: medium;
      margin-bottom: 0.5em;
    }
    .tels {
      margin: 5px 0;
      .tel {
        padding: 0;
        color: rgb(5, 102, 206);
      }
    }
  }
  .o-details {
    margin-top: 0.5em;
    width: calc(100% / 5);
    div {
      font-size: 17px;
      padding: 0;
      margin: 0;
      font-weight: 550;
      span {
        font-weight: 550;
      }
      span.bold {
        font-weight: 550;
      }
    }
    ._label,
    label {
      margin: 0;
      font-weight: 800;
    }
    .bold {
      font-weight: 900;
      font-size: 17px;
      flex-wrap: wrap;
    }
  }
}

.empty-_tab {
  padding: 1em;
}

.stage {
  background: transparent;
  border: 2px solid #111;
  padding: 0.5em 2em;
  width: 500px;
  border-radius: 5px;
  margin: 0.5em auto;
  font-weight: 800;
  transition: transform 0.5s;

  &:hover {
    transform: scale(0.9);
  }
  &:disabled:hover,
  &:disabled {
    transform: scale(1);
    cursor: not-allowed;
    border: 2px solid #999;
  }
}

.sales-cards {
  ._sales-card:nth-child(1) {
    border: none;
  }
}

@media only screen and (max-width: 600px) {
  ._tab {
    flex-direction: column;
  }
}
