.logo {
	padding: 10px 15px;
}

.nav-links {
	align-items: center;
	padding: 18px 20px;
	color: #fff;
	font-weight: bold;
}

button.nav-links {
	align-items: center;
	padding: 18px 20px;
	color: #fff;
	font-weight: bold;
	background: transparent;
	border: none;
}

.nav-drop-down-menu {
	padding: 10px 20px;
	font-weight: bold;
}

.nav-drop-down-menu .menu-items {
	padding: 15px 20px;
	font-weight: 400;
	display: block;
}

.nav-links:hover,
.nav-links:active,
.nav-drop-down-menu:hover,
.nav-drop-down-menu:active {
	background: rgba(255, 255, 255, 0.945);
	color: #111;
}

.nav-link:focus,
.nav-link:hover {
	color: var(--bs-nav-link-hover-color) !important;
}

.empty-text-notification {
	margin: .4rem;
	font-size: .7rem;
}

.dropdown-menu {
	margin-right: "1rem";
	right: 0;
	margin-top: 0.8rem;
	width: 15rem;
	/* margin-right: 1rem; */
}

.dropdown-item {
	/* border-bottom: 1px solid #B3C8CF !important; */
	cursor: pointer;
}

.custom-badge {
	position: absolute;
    top: 12%;
    right: 28%;
}